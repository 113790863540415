import styled from "@emotion/styled";
import React from "react";
import { variables } from "./variables";

interface Props {
  isPressed: boolean;
  label?: string;
  highlighted?: boolean;
  isAvailable?: boolean;
  isRoot?: boolean;
}

const WhiteKey: React.FC<Props> = ({
  isPressed,
  label,
  highlighted,
  isAvailable,
  isRoot,
}) => {
  const getKeyBackground = () => {
    // if (isRoot) return variables.color.highlight;
    if (highlighted) return variables.color.whitePressed;
    if (isPressed) return variables.color.whitePressed;
    return variables.color.white;
  };

  return (
    <Wrapper>
      {label && (
        <>
          <LabelConnector />
          <Label>{label}</Label>
        </>
      )}
      <Key background={getKeyBackground()} />
    </Wrapper>
  );
};

export default WhiteKey;

const Wrapper = styled.div`
  position: relative;

  width: 50px;
  height: 200px;

  @media (max-width: 1000px) {
    max-width: ${100 / 16}%;
  }
`;

const LabelConnector = styled.div`
  position: absolute;

  top: calc(100% + 5px);
  left: 50%;

  width: 0;
  height: 65px;

  border-right: 1px dashed #ddd;
`;

const Label = styled.div`
  position: absolute;

  bottom: -100px;
  width: 100%;

  color: #bbb;

  text-align: center;
`;

const Key = styled.div<{
  background?: string;
}>`
  display: inline-block;

  width: 100%;
  height: 100%;

  background: ${(props) => props.background};
  outline: 1px solid ${variables.color.grey};

  z-index: 1;
`;
