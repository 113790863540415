import styled from "@emotion/styled";
import React from "react";
import EditInversion from "./EditInversion";
import Keys from "./Keys";

const Piano = () => {
  return (
    <Wrapper>
      <Keys />
    </Wrapper>
  );
};

export default Piano;

const Wrapper = styled.div`
  padding-bottom: 100px;
`;
