import styled from "@emotion/styled";
import { find } from "lodash";
import React from "react";
import { useRecoilValue } from "recoil";
import { parseChord } from "../../utils/helpers";
import { pressedChordState } from "../../utils/state/atoms";
import BlackKey from "./BlackKey";
import { notes } from "./notes";
import WhiteKey from "./WhiteKey";

interface Props {
  index: number;
  isPressed: boolean;
  highlighted?: boolean;
  isLabelVisible?: boolean;
  isRoot?: boolean;
}

const Key: React.FC<Props> = ({
  index,
  isPressed,
  highlighted,
  isLabelVisible,
  isRoot,
}) => {
  const pressedChord = useRecoilValue(pressedChordState);
  const chord = pressedChord && parseChord(pressedChord);
  const note = notes[index];
  const { color, value, octave } = note;

  const label = value[0] + octave.toString();

  const noteName =
    highlighted &&
    find(value, (item) => chord?.notes?.includes(item)) + octave.toString();

  if (color === "black")
    return (
      <>
        <BlackKey
          label={isLabelVisible ? label : undefined}
          isPressed={isPressed}
          highlighted={highlighted}
          isRoot={isRoot}
        />
        {highlighted && (
          <NoteNameBlack>
            <Circle isRoot={isRoot}>{noteName}</Circle>
          </NoteNameBlack>
        )}
      </>
    );
  else
    return (
      <>
        <WhiteKey
          label={isLabelVisible ? label : undefined}
          isPressed={isPressed}
          highlighted={highlighted}
          isRoot={isRoot}
        />
        {highlighted && (
          <NoteName>
            <Circle isRoot={isRoot}>{noteName}</Circle>
          </NoteName>
        )}
      </>
    );
};

export default Key;

const NoteName = styled.div`
  pointer-events: none;

  display: flex;
  justify-content: center;

  z-index: 2;

  margin-top: -2.5rem;

  position: absolute;
  width: 100%;
  text-align: center;
`;

const NoteNameBlack = styled.div`
  pointer-events: none;

  z-index: 2;

  margin-top: -2.5rem;
  top: 200px;

  position: absolute;
  width: fit-content;
  text-align: center;
`;

const Circle = styled.div<{ isRoot?: boolean }>`
  padding: 0.5rem 0;
  color: ${(props) => props.isRoot && "red"};
  font-weight: bold;
`;
