import React from "react";

import upSrc from "../../images/keyboard-controls/up.svg";
import downSrc from "../../images/keyboard-controls/down.svg";
import leftSrc from "../../images/keyboard-controls/left.svg";
import rightSrc from "../../images/keyboard-controls/right.svg";
import enterSrc from "../../images/keyboard-controls/enter.svg";
import spaceSrc from "../../images/keyboard-controls/space.svg";
import Row from "./Row";

const keyCaps = [
  { icons: [upSrc], description: "Invert Chord UP." },
  { icons: [downSrc], description: "Invert Chord DOWN." },
  { icons: [leftSrc], description: "Go to PREVIOUS Chord." },
  { icons: [rightSrc, spaceSrc], description: "Go to NEXT Chord." },
  { icons: [enterSrc], description: "ADD Chord." },
];

const KeyCap: React.FC<{ icons: string[]; description: string }> = ({
  icons,
  description,
}) => {
  return (
    <Row
      gutter="5px"
      style={{ alignItems: "center", color: "#999", fontSize: 11 }}
    >
      {icons.map((src) => (
        <img src={src} alt={description} />
      ))}
      <span>{description}</span>
    </Row>
  );
};

const KeyCaps = () => {
  return (
    <>
      {keyCaps.map((keyCap) => {
        return (
          <KeyCap
            key={keyCap.description}
            icons={keyCap.icons}
            description={keyCap.description}
          />
        );
      })}
    </>
  );
};

export default KeyCaps;
