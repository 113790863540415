import styled from "@emotion/styled";
import React from "react";

const Row = (props) => {
  return <Wrapper {...props} />;
};
export default Row;

const Wrapper = styled.div<{ gutter?: string }>`
  display: flex;

  margin: 0 ${(props) => `-${props.gutter}`};

  & > * {
    margin: ${(props) => props.gutter};
  }
`;
