import styled from "@emotion/styled";
import move from "lodash-move";
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useRecoilState } from "recoil";
import { outputChordsState } from "../../utils/state/atoms";
import OutputChordBlock from "./OutputChordBlock";

const QueuedChords = () => {
  const [outputChords, setOutputChords] = useRecoilState(outputChordsState);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    setOutputChords(move(outputChords, source.index, destination.index));
  };

  if (outputChords.length < 1) return null;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="queued-chords" direction="horizontal">
        {(provided) => (
          <Wrapper ref={provided.innerRef} {...provided.droppableProps}>
            {outputChords.map((outputChord, i) => (
              <OutputChordBlock key={i} index={i} outputChord={outputChord} />
            ))}
            {provided.placeholder}
          </Wrapper>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default QueuedChords;

const Wrapper = styled.div``;
