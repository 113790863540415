export const variables = {
  color: {
    highlight: `red`,
    grey: `#bbb`,
    white: `#FDF8F8`,
    whitePressed: `#eee`,
    black: `#666`,
    blackPressed: `#111`,
  },
};
