import styled from "@emotion/styled";
import React from "react";

import { variables } from "./variables";

interface Props {
  isPressed: boolean;
  label?: string;
  highlighted?: boolean;
  isRoot?: boolean;
}

const BlackKey: React.FC<Props> = ({
  isPressed,
  label,
  highlighted,
  isRoot,
}) => {
  const getKeyBackground = () => {
    // if (isRoot) return variables.color.highlight;
    if (highlighted) return variables.color.blackPressed;
    if (isPressed) return variables.color.blackPressed;
    return variables.color.black;
  };

  return (
    <Wrapper>
      {label && (
        <>
          <LabelConnector />
          <Label>{label}</Label>
        </>
      )}
      <Key background={getKeyBackground()} />
    </Wrapper>
  );
};

export default BlackKey;

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;

  width: 30px;
  height: 140px;

  @media (max-width: 1000px) {
    width: ${70 / 16}%;
  }
`;

const LabelConnector = styled.div`
  position: absolute;

  top: calc(100% + 5px);

  width: 0;
  height: 65px;

  border-right: 1px dashed #ddd;
`;

const Label = styled.div`
  position: absolute;

  bottom: -100px;
  width: 100%;

  color: #bbb;

  text-align: center;
`;

const Key = styled.div<{ background?: string }>`
  position: absolute;

  top: 0;

  width: 100%;
  height: 100%;

  background: ${(props) => props.background};
  outline: 1px solid ${variables.color.grey};

  z-index: 1;
`;
