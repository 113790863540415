// TODO refactor to .env

const isRemote = true;

export default {
  api: {
    endpoint: isRemote
      ? `https://chord-name.herokuapp.com`
      : `http://localhost:5000`,
  },
};
