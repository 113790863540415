import styled from "@emotion/styled";

export const Mobile = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: contents;
  }
`;

export const Desktop = styled.div`
  display: none;
  @media (min-width: 1000px) {
    display: contents;
  }
`;
