import styled from "@emotion/styled";

export const ControlButton = styled.button`
  cursor: pointer;

  font-size: 16px;

  outline: none;
  border: none;

  border-radius: 4px;
  padding: 10px 15px;

  transition: background-color 0.1s ease;

  &:hover {
    background: #ddd;
  }
`;

export const GhostButton = styled.button`
  cursor: pointer;

  font-size: 16px;

  outline: none;
  border: none;

  border-radius: 4px;
  padding: 5px 10px;

  transition: background-color 0.1s ease;

  background: transparent;

  &:hover {
    background: #ddd;
  }
`;
