import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { useRecoilValue } from "recoil";
import { parseChord } from "../../utils/helpers";
import { pressedChordState } from "../../utils/state/atoms";
import { OutputChord } from "../../utils/types";
import { variables } from "../piano/variables";

const ChordInfoDisplay: React.FC<{
  condensed?: boolean;
  hoveredChord?: OutputChord;
}> = ({ condensed, hoveredChord }) => {
  const pressedChord = useRecoilValue(pressedChordState);

  if (!pressedChord) return null;

  const chord = hoveredChord || pressedChord;
  const parsedChord = parseChord(chord);

  const fields = [
    {
      key: "root",
      label: "Root",
      condition: parsedChord?.root,
      output: parsedChord?.root,
    },
    {
      key: "notes",
      label: "Notes",
      condition: parsedChord?.notes,
      output: parsedChord?.notes?.join(", "),
    },
    {
      key: "intervals",
      label: "Intervals",
      condition: parsedChord?.strings,
      output: parsedChord?.strings?.join(", "),
    },
    // {
    //   key: "keyValues",
    //   label: "Intervals",
    //   condition: parsedChord?.keyboard_values,
    //   output: parsedChord?.keyboard_values?.join(", "),
    // },
  ];

  return (
    <Wrapper condensed={condensed}>
      {!condensed && (
        <>
          <Title>{chord.original_input}</Title>
          <br />
        </>
      )}
      <Fields>
        {fields.map((field, i) => {
          if (!field.condition) return null;
          return <Field key={field.key} field={field} />;
        })}
      </Fields>
    </Wrapper>
  );
};

export default ChordInfoDisplay;

const Wrapper = styled.div<{ condensed?: boolean }>`
  position: relative;
  ${(props) =>
    props.condensed &&
    css`
      min-width: 180px;
      padding: 1rem;
      background: ${variables.color.white};
      border-radius: 4px;
      pointer-events: none;
    `}
`;

const Field = ({ field }) => {
  return (
    <StyledField>
      <span>{field.label}</span>
      <Output>{field.output}</Output>
    </StyledField>
  );
};

const Title = styled.h2`
  color: red;
`;

const Fields = styled.div`
  width: 140%;
`;

const StyledField = styled.div`
  margin-bottom: 1rem;
  position: relative;
`;

const Output = styled.span`
  position: absolute;
  width: max-content;
  left: 90px;
`;
