import styled from "@emotion/styled";
import React from "react";

import Header from "./Header";

import "./index.css";

const Layout: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <>{children}</>
    </Wrapper>
  );
};

export default Layout;

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    "header"
    "content"
    "footer";
  grid-template-rows: auto 1fr auto;
  max-width: 100vw;
  min-height: 100vh;
  padding: 2rem;
`;
