import axios from "axios";
import config from "./config";

export const getChords = async (input: string[]) => {
  const res = await axios.post(`${config.api.endpoint}/get_chord/`, {
    input,
  });

  if (res.status === 200) {
    return res;
  } else {
    console.log("error", res);
  }
};

export const testServer = async () => {
  const res = await axios.get(`${config.api.endpoint}/test_get/`);
  return res;
};
