export interface Note {
  index: number;
  color: "white" | "black";
  value: string[];
  octave: number;
}

export const notes: Note[] = [
  {
    index: 0,
    color: "white",
    value: ["A"],
    octave: 0
  },
  {
    index: 1,
    color: "black",
    value: ["A#", "Bb"],
    octave: 0
  },
  {
    index: 2,
    color: "white",
    value: ["B", "Cb"],
    octave: 0
  },
  {
    index: 3,
    color: "white",
    value: ["C", "B#"],
    octave: 0
  },
  {
    index: 4,
    color: "black",
    value: ["C#", "Db"],
    octave: 0
  },
  {
    index: 5,
    color: "white",
    value: ["D"],
    octave: 0
  },
  {
    index: 6,
    color: "black",
    value: ["D#", "Eb"],
    octave: 0
  },
  {
    index: 7,
    color: "white",
    value: ["E", "Fb"],
    octave: 0
  },
  {
    index: 8,
    color: "white",
    value: ["E#", "F"],
    octave: 0
  },
  {
    index: 9,
    color: "black",
    value: ["F#", "Gb"],
    octave: 0
  },
  {
    index: 10,
    color: "white",
    value: ["G"],
    octave: 0
  },
  {
    index: 11,
    color: "black",
    value: ["G#", "Ab"],
    octave: 0
  },
  {
    index: 12,
    color: "white",
    value: ["A"],
    octave: 1
  },
  {
    index: 13,
    color: "black",
    value: ["A#", "Bb"],
    octave: 1
  },
  {
    index: 14,
    color: "white",
    value: ["B", "Cb"],
    octave: 1
  },
  {
    index: 15,
    color: "white",
    value: ["C", "B#"],
    octave: 1
  },
  {
    index: 16,
    color: "black",
    value: ["C#", "Db"],
    octave: 1
  },
  {
    index: 17,
    color: "white",
    value: ["D"],
    octave: 1
  },
  {
    index: 18,
    color: "black",
    value: ["D#", "Eb"],
    octave: 1
  },
  {
    index: 19,
    color: "white",
    value: ["E", "Fb"],
    octave: 1
  },
  {
    index: 20,
    color: "white",
    value: ["E#", "F"],
    octave: 1
  },
  {
    index: 21,
    color: "black",
    value: ["F#", "Gb"],
    octave: 1
  },
  {
    index: 22,
    color: "white",
    value: ["G"],
    octave: 1
  },
  {
    index: 23,
    color: "black",
    value: ["G#", "Ab"],
    octave: 1
  },
  {
    index: 24,
    color: "white",
    value: ["A"],
    octave: 2
  },
  {
    index: 25,
    color: "black",
    value: ["A#", "Bb"],
    octave: 2
  },
  {
    index: 26,
    color: "white",
    value: ["B", "Cb"],
    octave: 2
  },
  {
    index: 27,
    color: "white",
    value: ["C", "B#"],
    octave: 2
  },
  {
    index: 28,
    color: "black",
    value: ["C#", "Db"],
    octave: 2
  },
  {
    index: 29,
    color: "white",
    value: ["D"],
    octave: 2
  },
  {
    index: 30,
    color: "black",
    value: ["D#", "Eb"],
    octave: 2
  },
  {
    index: 31,
    color: "white",
    value: ["E", "Fb"],
    octave: 2
  },
  {
    index: 32,
    color: "white",
    value: ["E#", "F"],
    octave: 2
  },
  {
    index: 33,
    color: "black",
    value: ["F#", "Gb"],
    octave: 2
  },
  {
    index: 34,
    color: "white",
    value: ["G"],
    octave: 2
  },
  {
    index: 35,
    color: "black",
    value: ["G#", "Ab"],
    octave: 2
  },
  {
    index: 36,
    color: "white",
    value: ["A"],
    octave: 3
  },
  {
    index: 37,
    color: "black",
    value: ["A#", "Bb"],
    octave: 3
  },
  {
    index: 38,
    color: "white",
    value: ["B", "Cb"],
    octave: 3
  },
  {
    index: 39,
    color: "white",
    value: ["C", "B#"],
    octave: 3
  },
  {
    index: 40,
    color: "black",
    value: ["C#", "Db"],
    octave: 3
  },
  {
    index: 41,
    color: "white",
    value: ["D"],
    octave: 3
  },
  {
    index: 42,
    color: "black",
    value: ["D#", "Eb"],
    octave: 3
  },
  {
    index: 43,
    color: "white",
    value: ["E", "Fb"],
    octave: 3
  },
  {
    index: 44,
    color: "white",
    value: ["E#", "F"],
    octave: 3
  },
  {
    index: 45,
    color: "black",
    value: ["F#", "Gb"],
    octave: 3
  },
  {
    index: 46,
    color: "white",
    value: ["G"],
    octave: 3
  },
  {
    index: 47,
    color: "black",
    value: ["G#", "Ab"],
    octave: 3
  },
  {
    index: 48,
    color: "white",
    value: ["A"],
    octave: 4
  },
  {
    index: 49,
    color: "black",
    value: ["A#", "Bb"],
    octave: 4
  },
  {
    index: 50,
    color: "white",
    value: ["B", "Cb"],
    octave: 4
  },
  {
    index: 51,
    color: "white",
    value: ["C", "B#"],
    octave: 4
  },
  {
    index: 52,
    color: "black",
    value: ["C#", "Db"],
    octave: 4
  },
  {
    index: 53,
    color: "white",
    value: ["D"],
    octave: 4
  },
  {
    index: 54,
    color: "black",
    value: ["D#", "Eb"],
    octave: 4
  },
  {
    index: 55,
    color: "white",
    value: ["E", "Fb"],
    octave: 4
  },
  {
    index: 56,
    color: "white",
    value: ["E#", "F"],
    octave: 4
  },
  {
    index: 57,
    color: "black",
    value: ["F#", "Gb"],
    octave: 4
  },
  {
    index: 58,
    color: "white",
    value: ["G"],
    octave: 4
  },
  {
    index: 59,
    color: "black",
    value: ["G#", "Ab"],
    octave: 4
  },
  {
    index: 60,
    color: "white",
    value: ["A"],
    octave: 5
  },
  {
    index: 61,
    color: "black",
    value: ["A#", "Bb"],
    octave: 5
  },
  {
    index: 62,
    color: "white",
    value: ["B", "Cb"],
    octave: 5
  },
  {
    index: 63,
    color: "white",
    value: ["C", "B#"],
    octave: 5
  },
  {
    index: 64,
    color: "black",
    value: ["C#", "Db"],
    octave: 5
  },
  {
    index: 65,
    color: "white",
    value: ["D"],
    octave: 5
  },
  {
    index: 66,
    color: "black",
    value: ["D#", "Eb"],
    octave: 5
  },
  {
    index: 67,
    color: "white",
    value: ["E", "Fb"],
    octave: 5
  },
  {
    index: 68,
    color: "white",
    value: ["E#", "F"],
    octave: 5
  },
  {
    index: 69,
    color: "black",
    value: ["F#", "Gb"],
    octave: 5
  },
  {
    index: 70,
    color: "white",
    value: ["G"],
    octave: 5
  },
  {
    index: 71,
    color: "black",
    value: ["G#", "Ab"],
    octave: 5
  },
  {
    index: 72,
    color: "white",
    value: ["A"],
    octave: 6
  },
  {
    index: 73,
    color: "black",
    value: ["A#", "Bb"],
    octave: 6
  },
  {
    index: 74,
    color: "white",
    value: ["B", "Cb"],
    octave: 6
  },
  {
    index: 75,
    color: "white",
    value: ["C", "B#"],
    octave: 6
  },
  {
    index: 76,
    color: "black",
    value: ["C#", "Db"],
    octave: 6
  },
  {
    index: 77,
    color: "white",
    value: ["D"],
    octave: 6
  },
  {
    index: 78,
    color: "black",
    value: ["D#", "Eb"],
    octave: 6
  },
  {
    index: 79,
    color: "white",
    value: ["E", "Fb"],
    octave: 6
  },
  {
    index: 80,
    color: "white",
    value: ["E#", "F"],
    octave: 6
  },
  {
    index: 81,
    color: "black",
    value: ["F#", "Gb"],
    octave: 6
  },
  {
    index: 82,
    color: "white",
    value: ["G"],
    octave: 6
  },
  {
    index: 83,
    color: "black",
    value: ["G#", "Ab"],
    octave: 6
  },
  {
    index: 84,
    color: "white",
    value: ["A"],
    octave: 7
  },
  {
    index: 85,
    color: "black",
    value: ["A#", "Bb"],
    octave: 7
  },
  {
    index: 86,
    color: "white",
    value: ["B", "Cb"],
    octave: 7
  },
  {
    index: 87,
    color: "white",
    value: ["C", "B#"],
    octave: 7
  }
];
