import { flatten } from "lodash";
import { Chord, OutputChord } from "./types";

export const parseChord: (outputChord: OutputChord) => Chord = (
  outputChord
) => {
  if (outputChord.polychord)
    return {
      root: outputChord.chords.map((chord) => chord.root).join("|"),
      notes: flatten(outputChord.chords.map((chord) => chord.notes!)),
      strings: flatten(outputChord.chords.map((chord) => chord.strings!)),
      keyboard_values: flatten(
        outputChord.chords.map((chord) => chord.keyboard_values!)
      ),
    };
  return outputChord.chords[0];
};
