import { CloseOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Soundfont from "soundfont-player";
import { parseChord } from "../../utils/helpers";
import {
  currentChordKeyState,
  keyboardRangeState,
  outputChordsState,
  pressedChordState,
} from "../../utils/state/atoms";
import { Chord, OutputChord } from "../../utils/types";
import EditInversion from "./EditInversion";
import Key from "./Key";
import { Note, notes } from "./notes";

const { instrument } = Soundfont;

const Keys: React.FC = () => {
  const keyboardRange = useRecoilValue(keyboardRangeState);

  const pressedChord = useRecoilValue(pressedChordState);
  const chord: Chord = pressedChord ? parseChord(pressedChord) : {};

  const [pressedNotes, setPressedNotes] = useState<number[]>([]);
  const [instr, setInstr] = useState<Soundfont.Player | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const availableKeys = notes.filter(
    (note) =>
      note.index >= keyboardRange.start && note.index <= keyboardRange.end
  );

  const keys = chord?.keyboard_values;

  useEffect(() => {
    const loadInstrument = async () => {
      const instr = await instrument(
        new AudioContext(),
        "acoustic_grand_piano"
      );
      if (instr) {
        setInstr(instr);
        setLoading(false);
      }
    };
    loadInstrument();
  }, []);

  const soundKey = (index: number) => instr?.play(JSON.stringify(index + 21)); // Offsets instrument indexes to shift the semitones up by 3

  useEffect(() => {
    instr?.stop();
    if (keys) keys.forEach((i) => soundKey(i)); // Offsets instrument indexes to shift the semitones up by 3
  }, [instr, keys]);

  const [outputChords, setOutputChords] = useRecoilState(outputChordsState);
  const currentChordKey = useRecoilValue(currentChordKeyState);
  const setPressedChord = useSetRecoilState(pressedChordState);

  if (loading) return <>Loading Piano...</>;

  return (
    <Wrapper>
      <KeysWrapper>
        {availableKeys.map((key: Note) => {
          const { index } = key;

          const { color, value } = notes[index];

          const isKeyInChord = keys?.includes(index);
          const isPressed = pressedNotes.includes(index);
          const isRoot =
            isKeyInChord &&
            pressedChord &&
            value.includes(parseChord(pressedChord)?.root || "");
          const isLabelVisible =
            index === keyboardRange.start || index === keyboardRange.end;

          const handlePress = () => {
            setPressedNotes([...pressedNotes, index]);
            soundKey(index);
          };

          const editVoicing = () => {
            if (!pressedChord) return;
            const oldChordValues: Chord = parseChord(pressedChord);

            const newKeyboardValues: () => number[] = () => {
              if (!oldChordValues.keyboard_values)
                return (oldChordValues.keyboard_values as unknown) as number[];
              if (oldChordValues.keyboard_values.includes(index))
                return oldChordValues.keyboard_values.filter(
                  (i) => i !== index
                );
              else
                return [...oldChordValues.keyboard_values, index].sort(
                  (a, b) => a - b
                );
            };

            const newVoicedChord: OutputChord = {
              ...pressedChord,
              hasVoicing: true,
              chords: [
                {
                  ...oldChordValues,
                  keyboard_values: newKeyboardValues(),
                },
              ],
            };

            setPressedChord(newVoicedChord);
            setOutputChords(
              outputChords.map((outputChord, i) =>
                i === currentChordKey ? newVoicedChord : outputChord
              )
            );
          };

          const handleRelease = () =>
            setPressedNotes(pressedNotes.filter((i) => i !== index));

          const isAvailable = pressedChord?.available_values?.includes(index);

          return (
            <div
              key={index}
              style={{
                display: "inline-block",
                position: "relative",
                transform:
                  color === "black" ? "translateX(-17.5px)" : undefined,
                zIndex: color === "black" ? 1 : undefined,
              }}
              onMouseDown={handlePress}
              onMouseUp={handleRelease}
              onMouseOut={handleRelease}
            >
              <Key
                index={index}
                isPressed={isPressed}
                highlighted={isKeyInChord}
                isLabelVisible={isLabelVisible}
                isRoot={isRoot}
              />
              {isAvailable && (
                <VoicingButton
                  onClick={editVoicing}
                  isWhite={color === "white"}
                >
                  <div className="indicator" />
                  {isKeyInChord ? (
                    <CloseOutlined className="icon" />
                  ) : (
                    <span className="icon">Add</span> // <PlusOutlined className="icon" />
                  )}
                </VoicingButton>
              )}
            </div>
          );
        })}
      </KeysWrapper>
      <EditInversion />
    </Wrapper>
  );
};

export default Keys;

const Wrapper = styled.div`
  position: relative;
`;

const KeysWrapper = styled.div`
  display: flex;
`;

const VoicingButton = styled.button<{ isWhite?: boolean }>`
  cursor: pointer;

  position: absolute;
  width: ${(props) => (props.isWhite ? "100%" : "35px")};

  background: transparent;
  outline: none;
  border: none;

  padding: 0;
  padding-top: 70px;
  bottom: -35px;
  text-align: center;

  z-index: 1;

  & .indicator {
    opacity: 1;

    position: absolute;
    width: 100%;
    height: 5px;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #333;

    bottom: 30px;

    transition: opacity 0.1s ease;
  }

  &:hover .indicator {
    opacity: 0.2;
  }

  & .icon {
    opacity: 0;
    font-size: 20px;
    top: 100px;
    transition: opacity 0.1s ease;
  }

  &:hover .icon {
    opacity: 1;
  }
`;
