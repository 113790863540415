import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { RecoilRoot } from "recoil";
import APITest from "./components/APITest";
import Landing from "./components/Landing";
import { testServer } from "./utils/api";

const App = () => {
  testServer();

  return (
    <RecoilRoot>
      <Router>
        <Switch>
          <Route exact path="/" children={<Landing />} />
          <Route path="/api-test" children={<APITest />} />
        </Switch>
      </Router>
    </RecoilRoot>
  );
};

export default App;
