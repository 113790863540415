import { atom } from "recoil";
import { OutputChord } from "../types";

export const keyboardRangeState = atom({
  key: "keyboardRangeState",
  default: {
    start: 44,
    end: 82,
  },
});

export const pressedChordState = atom({
  key: "pressedChordState",
  default: undefined as OutputChord | undefined,
});

export const inputChordsState = atom({
  key: "inputChordsState",
  default: [] as string[],
});

export const outputChordsState = atom({
  key: "outputChordsState",
  default: [] as OutputChord[],
});

export const currentChordKeyState = atom({
  key: "currentChordKeyState",
  default: 0 as number,
});
