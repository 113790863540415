import { first, last } from "lodash";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { parseChord } from "../../utils/helpers";
import {
  currentChordKeyState,
  outputChordsState,
  pressedChordState,
} from "../../utils/state/atoms";

import { Chord, OutputChord } from "../../utils/types";
import { GhostButton } from "../ui/Button";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";

const InversionSlider = () => {
  const currentChordKey = useRecoilValue(currentChordKeyState);
  const [pressedChord, setPressedChord] = useRecoilState(pressedChordState);
  const [outputChords, setOutputChords] = useRecoilState(outputChordsState);

  if (!pressedChord) return null;

  const showPreviousInversion =
    first(parseChord(pressedChord).keyboard_values)! >
      first(pressedChord.available_values)! &&
    first(parseChord(pressedChord).keyboard_values)! > 0; // keyboard starts at

  const showNextInversion =
    last(parseChord(pressedChord).keyboard_values)! <
    last(pressedChord.available_values)!;

  return (
    <Wrapper>
      <GhostButton
        disabled={!showPreviousInversion}
        onClick={() =>
          previousInversion({
            showPreviousInversion,
            pressedChord,
            setPressedChord,
            setOutputChords,
            outputChords,
            currentChordKey,
          })
        }
      >
        <LeftOutlined />
      </GhostButton>
      <Label>Inversions</Label>
      <GhostButton
        disabled={!showNextInversion}
        onClick={() =>
          nextInversion({
            showNextInversion,
            pressedChord,
            setPressedChord,
            setOutputChords,
            outputChords,
            currentChordKey,
          })
        }
      >
        <RightOutlined />
      </GhostButton>
    </Wrapper>
  );
};

export default InversionSlider;

const Wrapper = styled.div`
  width: fit-content;
`;

const Label = styled.label`
  margin: 0 1rem;
`;

export const previousInversion = ({
  showPreviousInversion,
  pressedChord,
  setPressedChord,
  setOutputChords,
  outputChords,
  currentChordKey,
}) => {
  if (!showPreviousInversion) return;
  const oldChordValues: Chord = parseChord(pressedChord);

  const newKeyboardValues = oldChordValues.keyboard_values!.map((value) => {
    const currentValueIndex = pressedChord.available_values.indexOf(value);
    const previousValue = pressedChord.available_values[currentValueIndex - 1];
    return previousValue;
  });

  const newChord: OutputChord = {
    ...pressedChord,
    hasVoicing: true,
    chords: [
      {
        ...oldChordValues,
        keyboard_values: newKeyboardValues,
      },
    ],
  };

  setPressedChord(newChord);
  setOutputChords(
    outputChords.map((outputChord, i) =>
      i === currentChordKey ? newChord : outputChord
    )
  );
};

export const nextInversion = ({
  showNextInversion,
  pressedChord,
  setPressedChord,
  setOutputChords,
  outputChords,
  currentChordKey,
}) => {
  if (!showNextInversion) return;
  const oldChordValues: Chord = parseChord(pressedChord);

  const newKeyboardValues = oldChordValues.keyboard_values!.map((value) => {
    const currentValueIndex = pressedChord.available_values.indexOf(value);
    const nextValue = pressedChord.available_values[currentValueIndex + 1];
    return nextValue;
  });

  const newChord: OutputChord = {
    ...pressedChord,
    hasVoicing: true,
    chords: [
      {
        ...oldChordValues,
        keyboard_values: newKeyboardValues,
      },
    ],
  };

  setPressedChord(newChord);
  setOutputChords(
    outputChords.map((outputChord, i) =>
      i === currentChordKey ? newChord : outputChord
    )
  );
};
