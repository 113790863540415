import { CopyFilled, StepForwardFilled } from "@ant-design/icons";
import cogoToast from "cogo-toast";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  currentChordKeyState,
  inputChordsState,
  outputChordsState,
  pressedChordState,
} from "../../utils/state/atoms";
import { ControlButton } from "../ui/Button";
import Row from "../ui/Row";
import InputChords from "./InputChords";

const Controls = () => {
  const [outputChords, setOutputChords] = useRecoilState(outputChordsState);
  const setInputChords = useSetRecoilState(inputChordsState);
  const setPressedChord = useSetRecoilState(pressedChordState);
  const [currentChordKey, setCurrentChordKey] = useRecoilState(
    currentChordKeyState
  );

  const reset = () => {
    setInputChords([]);
    setOutputChords([]);
    setCurrentChordKey(0);
  };

  const copy = () => {
    cogoToast.success(`Copied ${outputChords.length} chords!`);
  };

  const areOutputChordsVisible = outputChords.length > 0;

  return (
    <Row gutter="5px">
      <InputChords />
      {areOutputChordsVisible && (
        <>
          <ControlButton
            onClick={() =>
              playNextChord({
                currentChordKey,
                outputChords,
                setCurrentChordKey,
                setPressedChord,
              })
            }
          >
            <StepForwardFilled /> Play
          </ControlButton>
          <CopyToClipboard
            text={outputChords
              .map((outputChord) => outputChord.original_input)
              .join(" ")}
            onCopy={copy}
          >
            <ControlButton>
              Copy <CopyFilled />
            </ControlButton>
          </CopyToClipboard>
          <ControlButton onClick={reset}>Reset</ControlButton>
        </>
      )}
    </Row>
  );
};

export default Controls;

export const playPreviousChord = ({
  currentChordKey,
  outputChords,
  setCurrentChordKey,
  setPressedChord,
}) => {
  const previousChordKey = currentChordKey - 1;

  if (currentChordKey === 0) {
    setCurrentChordKey(outputChords.length - 1);
    setPressedChord(outputChords[outputChords.length - 1]);
  } else {
    setCurrentChordKey(previousChordKey);
    setPressedChord(outputChords[previousChordKey]);
  }
};

export const playNextChord = ({
  currentChordKey,
  outputChords,
  setCurrentChordKey,
  setPressedChord,
}) => {
  const nextChordKey = currentChordKey + 1;

  if (currentChordKey > outputChords.length - 2) {
    setCurrentChordKey(0);
    setPressedChord(outputChords[0]);
  } else {
    setCurrentChordKey(nextChordKey);
    setPressedChord(outputChords[nextChordKey]);
  }
};
