import styled from "@emotion/styled";
import React from "react";
import Controls from "./Controls";
import QueuedChords from "./QueuedChords";

export const DesktopTimeline = () => {
  return (
    <Wrapper>
      <Controls />
      <br />
      <QueuedChords />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  & > * {
    margin-bottom: 5px;
  }
`;
