import { first, last } from "lodash";
import React from "react";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { useRecoilState } from "recoil";
import { parseChord } from "../utils/helpers";
import {
  currentChordKeyState,
  outputChordsState,
  pressedChordState,
} from "../utils/state/atoms";
import { nextInversion, previousInversion } from "./piano/InversionSlider";
import { playNextChord, playPreviousChord } from "./timeline/Controls";

const KeyboardEventHandlers = () => {
  const [currentChordKey, setCurrentChordKey] = useRecoilState(
    currentChordKeyState
  );
  const [pressedChord, setPressedChord] = useRecoilState(pressedChordState);
  const [outputChords, setOutputChords] = useRecoilState(outputChordsState);

  const showPreviousInversion =
    pressedChord &&
    first(parseChord(pressedChord).keyboard_values)! >
      first(pressedChord.available_values)! &&
    first(parseChord(pressedChord).keyboard_values)! > 0; // keyboard starts at

  const showNextInversion =
    pressedChord &&
    last(parseChord(pressedChord).keyboard_values)! <
      last(pressedChord.available_values)!;

  return (
    <>
      <KeyboardEventHandler
        handleKeys={["down"]}
        onKeyEvent={() =>
          previousInversion({
            showPreviousInversion,
            pressedChord,
            setPressedChord,
            setOutputChords,
            outputChords,
            currentChordKey,
          })
        }
      />
      <KeyboardEventHandler
        handleKeys={["up"]}
        onKeyEvent={() =>
          nextInversion({
            showNextInversion,
            pressedChord,
            setPressedChord,
            setOutputChords,
            outputChords,
            currentChordKey,
          })
        }
      />
      <KeyboardEventHandler
        handleKeys={["right", "space"]}
        onKeyEvent={() =>
          playNextChord({
            currentChordKey,
            outputChords,
            setCurrentChordKey,
            setPressedChord,
          })
        }
      />
      <KeyboardEventHandler
        handleKeys={["left"]}
        onKeyEvent={() =>
          playPreviousChord({
            currentChordKey,
            outputChords,
            setCurrentChordKey,
            setPressedChord,
          })
        }
      />
    </>
  );
};

export default KeyboardEventHandlers;
