import { LoadingOutlined } from "@ant-design/icons";
import cogoToast from "cogo-toast";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { getChords } from "../../utils/api";
import { inputChordsState, outputChordsState } from "../../utils/state/atoms";
import { Input, InputButton } from "../ui/Input";

const InputChords = () => {
  const [inputChords, setInputChords] = useRecoilState(inputChordsState);
  const [outputChords, setOutputChords] = useRecoilState(outputChordsState);

  const [input, setInput] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const addChords = () => {
    const addedInputChords = input.split(/\s+/).filter((chords) => chords);
    setInputChords(addedInputChords);
  };

  useEffect(() => {
    const fetchChords = async () => {
      setLoading(true);

      try {
        const res = await getChords(inputChords);
        if (res) {
          setInput("");
          setOutputChords([...outputChords, ...res.data.chords]);
          setLoading(false);
        }
      } catch (error) {
        console.log("error", error);
        cogoToast.error("There was an error with one of your inputs!");
        setLoading(false);
      }
    };
    fetchChords();
  }, [inputChords]);

  const showAddChords = () => {
    if (loading) return false;
    if (input.length > 0) return true;
  };

  return (
    <div>
      <Input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && addChords()}
        placeholder="Enter Chords separated by spaces"
      />
      <InputButton onClick={addChords} active={showAddChords()}>
        Add {loading && <LoadingOutlined />}
      </InputButton>
    </div>
  );
};

export default InputChords;
