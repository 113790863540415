import { CloseCircleFilled } from "@ant-design/icons";
import styled from "@emotion/styled";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useRecoilState, useSetRecoilState } from "recoil";
import customVoicingIcon from "../../images/custom-voicing-icon.svg";
import {
  currentChordKeyState,
  outputChordsState,
  pressedChordState,
} from "../../utils/state/atoms";
import { OutputChord } from "../../utils/types";
import ChordInfoDisplay from "../chord-info-display";

const OutputChordBlock: React.FC<{
  outputChord: OutputChord;
  index: number;
}> = ({ outputChord, index }) => {
  const [outputChords, setOutputChords] = useRecoilState(outputChordsState);
  const setPressedChord = useSetRecoilState(pressedChordState);

  const [currentChordKey, setCurrentChordKey] = useRecoilState(
    currentChordKeyState
  );

  const isActive = currentChordKey === index;

  const selectChord = () => {
    setCurrentChordKey(index);
    setPressedChord(outputChords[index]);
  };

  const deleteChord = () => {
    if (currentChordKey > index) setCurrentChordKey(currentChordKey - 1); // rearranges current chord index if the any previous ones are deleted.
    setOutputChords(outputChords.filter((outputChord, i) => i !== index));
  };

  return (
    <Draggable draggableId={`${index}`} index={index}>
      {(provided) => (
        <Wrapper
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <StyledOutputChord onClick={selectChord} isActive={isActive}>
            {outputChord.original_input}{" "}
            {outputChord.hasVoicing && (
              <img
                alt="Custom Voicing Button"
                style={{ width: 24, marginLeft: 6 }}
                src={customVoicingIcon}
              />
            )}
          </StyledOutputChord>
          <Info className="info">
            <ChordInfoDisplay condensed hoveredChord={outputChord} />
          </Info>
          <Delete onClick={deleteChord} className="delete" />
        </Wrapper>
      )}
    </Draggable>
  );
};

export default OutputChordBlock;

const Wrapper = styled.div<{ isActive?: boolean }>`
  position: relative;

  display: inline-block;

  user-select: none;

  border: 1px solid transparent;
  border-radius: 4px;
  color: ${(props) => props.isActive && "red"};

  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover {
    border-color: ${(props) => !props.isActive && "#ddd"};
  }

  & .delete {
    cursor: pointer;
    margin: auto;
    position: relative;
    width: 100%;
    opacity: 0;
    transition: opacity 0.1s ease;
  }

  &:hover .delete {
    opacity: 1;
  }

  & .info {
    display: none;
  }
  &:hover .info {
    display: block;
  }
`;

const StyledOutputChord = styled.div<{ isActive?: boolean }>`
  cursor: pointer;

  display: flex;
  align-items: center;

  width: fit-content;
  font-size: 24pt;

  padding: 5px 15px 0 15px;
  margin-bottom: 0;

  color: ${(props) => props.isActive && "red"};

  transition: color 0.3s ease;
`;

const Delete = styled(CloseCircleFilled)`
  font-size: 13pt;

  padding: 5px 15px 15px 15px;
  color: #ddd;

  &:hover {
    color: #333;
  }
`;

const Info = styled.div`
  z-index: 1;
  border: 1px solid #ddd;
  position: absolute;
  top: 0;
  left: 120%;
`;
