import React from "react";
import { Link } from "react-router-dom";
import KeyCaps from "../ui/KeyCaps";
import Row from "../ui/Row";

const FooterLinks = () => {
  return (
    <Row
      style={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Row gutter="10px">
        <Link to="/">Home</Link>
        <Link to="/api-test">Test the API</Link>
        <a target="blank" href="https://gitlab.com/jono.vu/numpy-chords">
          GitLab
        </a>
      </Row>
      <Row gutter="10px">
        <KeyCaps />
      </Row>
    </Row>
  );
};

export default FooterLinks;
