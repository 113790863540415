import styled from "@emotion/styled";

export const Input = styled.input`
  font-size: 16px;

  min-width: 400px;

  outline: none;
  border: 1px solid #ddd;
  border-right: none;
  background: transparent;

  border-radius: 4px 0 0 4px;
  padding: 10px 15px;

  &::placeholder {
    color: #999;
  }
`;

export const InputButton = styled.button<{ active?: boolean }>`
  cursor: pointer;
  pointer-events: ${(props) => (props.active ? undefined : "none")};

  font-size: 16px;

  outline: none;
  border: 1px solid #ddd;
  border-radius: 0 4px 4px 0;
  border-color: ${(props) => (props.active ? "#333" : undefined)};
  border-left-color: ${(props) => (props.active ? undefined : "transparent")};

  color: ${(props) => (props.active ? "white" : "#bbb")};
  background: ${(props) => (props.active ? "#333" : "transparent")};

  padding: 10px 15px;

  transition: background-color 0.1s ease;
`;
