import React, { useState } from "react";

import ReactJson from "react-json-view";

import Spinner from "./ui/Spinner";
import Layout from "./layout";

import Card from "antd/lib/card";
import Alert from "antd/lib/alert";
import { getChords } from "../utils/api";
import { Input, InputButton } from "./ui/Input";
import { EnterOutlined, LoadingOutlined } from "@ant-design/icons";
import FooterLinks from "./footerLinks";
import styled from "@emotion/styled";

const TestDataCall = () => {
  const [input, setInput] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [output, setOutput] = useState<Object>({});

  const [result, setResult] = useState<"success" | "error" | undefined>();

  const handleSubmit = async () => {
    const inputChords = input.split(/\s+/).filter((notes) => notes);

    setLoading(true);

    try {
      const res = await getChords(inputChords);
      if (res) {
        setInput("");
        setOutput(res);
        setResult("success");
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setResult("error");
      setOutput(error);
      setLoading(false);
    }
  };

  const showAddChords = () => {
    if (loading) return false;
    if (input.length > 0) return true;
  };

  return (
    <Layout>
      <Content>
        <div>
          <Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={async (e) => e.key === "Enter" && (await handleSubmit())}
            placeholder="Enter Chords separated by spaces"
          />
          <InputButton
            onClick={async () => await handleSubmit()}
            active={showAddChords()}
          >
            Add {loading ? <LoadingOutlined /> : <EnterOutlined />}
          </InputButton>
        </div>

        {result && <Alert message={result} type={result} showIcon />}
        <ReactJson src={output} />
      </Content>
      <FooterLinks />
    </Layout>
  );
};

export default TestDataCall;

const Content = styled.div`
  & > * {
    margin: 20px 0;
    width: fit-content;
  }
`;
