import styled from "@emotion/styled";
import React from "react";
import ChordInfoDisplay from "./chord-info-display";
import FooterLinks from "./footerLinks";
import KeyboardEventHandlers from "./KeyboardEventHandlers";
import Layout from "./layout";
import Piano from "./piano";
import { Desktop, Mobile } from "./responsive";
import { DesktopTimeline } from "./timeline";
import Row from "./ui/Row";

const Landing = () => {
  const renderDesktop = () => (
    <Desktop>
      <KeyboardEventHandlers />
      <Content>
        <Row gutter="20px">
          <Piano />
          <ChordInfoDisplay />
        </Row>
        <DesktopTimeline />
      </Content>
      <FooterLinks />
    </Desktop>
  );

  const renderMobile = () => (
    <Mobile>
      <br />
      <span>
        <em>chord.name</em> is a Desktop Application.
      </span>
      <p>Please open it on your Computer!</p>
      <div />
    </Mobile>
  );
  const mobile = renderMobile();
  const desktop = renderDesktop();

  return (
    <Layout>
      {mobile}
      {desktop}
    </Layout>
  );
};

export default Landing;

const Content = styled.div`
  & > * {
    margin: 60px 0;
  }
`;
